<template>
  <div class="programme">
    <base-box
      :topBarIcon="recordSearchIcon"
      topBarLabel="方案查询记录"
      boxWidth="95%"
      boxHeight="520px"
      style="margin-top: 20px;"
    >
      <div slot="content" class="content">
        <div class="table" style="width: 100%; min-height:300px;">
          <el-table :data="searchTable" stripe>
            <el-table-column
              type="index"
              label="序号"
              width="160px"
              align="center"
            />
            <el-table-column
              prop="updateTime"
              label="方案查询时间"
              align="center"
            />
            <el-table-column prop="majorName" label="查询专业" align="center" />
            <el-table-column
              prop="studentType"
              label="查询考生类型"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.studentType | studentType }}
              </template>
            </el-table-column>
            <el-table-column
              prop="score"
              label="查询的考生分数"
              width="180px"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.score ? scope.row.score : "-" }} |
                {{
                  scope.row.studentType !== 1 && scope.row.majorScore
                    ? scope.row.majorScore
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column label="查看详情" align="center">
              <template slot-scope="scope">
                <span class="check" @click="checkMore(scope.row)">编辑</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.pageNo"
          :page-sizes="[5, 10, 20, 30]"
          :page-size="pagination.pageSize"
          layout="prev, pager, next, total, sizes, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </base-box>
    <base-dialog
      baseDialogWidth="95%"
      :baseDialogVisible="schemlDialogVisible"
      :baseDialogTitleShow="false"
      :baseDialogShowClose="true"
      @closeDialog="closeSchemlDialog"
    >
      <div>
        <div style="margin-bottom:10px; text-align: right;">
          <el-button type="primary" @click="handleExport('excel')"
            >导出Excel</el-button
          >
          <el-button type="primary" @click="handleExport('pdf')"
            >导出PDF</el-button
          >
        </div>
        <div
          class="table"
          style="width:100%; min-height: 300px;margin-bottom:20px;"
        >
          <el-table
            :data="schemlTable"
            stripe
            :row-class-name="tableRowClassName"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="60px"
            />
            <el-table-column
              prop="majorId"
              label="专业代号"
              width="60px"
              align="center"
            />
            <el-table-column prop="majorName" label="专业名称" align="center" />
            <el-table-column
              prop="academyId"
              label="院校代号"
              width="60px"
              align="center"
            />
            <el-table-column prop="academy" label="院校名称" align="center" />
            <el-table-column
              prop="courseDemand"
              label="选科要求"
              width="115px"
              align="center"
            />
            <el-table-column
              prop="nature"
              label="学校性质"
              width="95px"
              align="center"
            />
            <el-table-column
              prop="schoolAddress"
              label="学校地址"
              align="center"
            />
            <el-table-column
              prop="yearLimit"
              label="学制"
              width="65px"
              align="center"
            />
            <el-table-column prop="" label="普通类" align="center">
              <el-table-column
                prop="planNum"
                label="计划数"
                width="50px"
                align="center"
              />
              <el-table-column
                prop="annualFee"
                label="年收费 （元）"
                width="65px"
                align="center"
              />
              <!-- <el-table-column prop="castArchivesNum" label="投档计划数" align="center" />
              <el-table-column prop="launchNum" label="投出数" width="60px;" align="center" /> -->
              <el-table-column
                prop="lowestMark"
                label="参考分"
                width="65px"
                align="center"
              />
              <el-table-column
                prop="lowestRank"
                label="最低位次"
                width="95px"
                align="center"
              />
            </el-table-column>
            <el-table-column
              prop="yearLimit"
              label="志愿操作"
              align="center"
              width="173px"
            >
              <template slot-scope="scope">
                <el-button
                  @click="handleClickDelete(scope.row)"
                  type="text"
                  size="small"
                >
                  删除</el-button
                >
                <el-button
                  v-if="scope.$index !== 0"
                  @click="handleClickPre(scope.row, scope.$index)"
                  type="text"
                  size="small"
                >
                  上移</el-button
                >
                <el-button
                  v-if="scope.$index !== schemlTable.length - 1"
                  @click="handleClickNext(scope.row, scope.$index)"
                  type="text"
                  size="small"
                >
                  下移</el-button
                >
                <el-button
                  @click="handleClickAdd(scope.row)"
                  type="text"
                  size="small"
                >
                  插入</el-button
                >
                <el-button
                  @click="handleClickChoice(scope.row)"
                  type="text"
                  size="small"
                >
                  调整志愿</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </base-dialog>
    <div class="opttllo">
    <base-dialog
      :baseDialogVisible="clickChoiceVisible"
      baseDialogWidth="38%"
      :baseDialogTitleShow="true"
      :baseDialogShowClose="true"
      @closeDialog="clickChoiceDialog"
      :modalAppend="false"
    >
      <span slot="dialog-title" style="text-align:left;">志愿调整:</span>
      <div>
        <!-- <div>将当前志愿调整为第几志愿:</div> -->
        <div class="choice">
          将当前志愿调整为第几志愿:
          <el-input
            class="inputChoice"
            v-model="input"
            placeholder="请输入要调整到的志愿序号"
          ></el-input>
        </div>
      </div>
      <span class="dialog-footer" slot="dialog-footer">
        <el-button @click="cancleH">取消</el-button>
        <el-button type="primary" @click="checkChoice">确定</el-button>
      </span>
    </base-dialog>
    </div>
  </div>
</template>

<script>
import { getUserId, getUserName } from "@/utils/auth";
import {
  shcemeRecord,
  getVolunteerList,
  exportSchemePDF,
  exportSchemeExcel,
  delRecord,
  updSchemeOrder,
  updateOrder,
} from "@/api/programme";
import BaseBox from "@/components/BaseBox/index.vue";
import BaseDialog from "@/components/BaseDialog/index.vue";

const globalRecordSearchIcon = require("@/assets/images/icon_record@3x.png");

export default {
  name: "Programme",
  components: {
    BaseBox,
    BaseDialog,
  },
  filters: {
    studentType(type) {
      let result = "";
      switch (type) {
        case 1:
          result = "文化课考生";
          break;
        case 2:
          result = "美术生";
          break;
        case 3:
          result = "体育生";
          break;
        case 4:
          result = "文学编导考生";
          break;
        case 5:
          result = "书法类考生";
          break;
        case 6:
          result = "舞蹈类考生";
          break;
        case 7:
          result = "音乐生";
          break;
        case 8:
          result = "播音主持类考生";
          break;
        case 9:
          result = "影视戏剧表演生";
          break;
        case 10:
          result = "服装表演类考生";
          break;
        case 11:
          result = "航空服务艺术类考生";
          break;
        case 12:
          result = "摄影类考生";
          break;
        default:
          result = "";
          break;
      }
      return result;
    },
  },
  data() {
    return {
      volId: "",
      numChoice: "",
      input: "",
      clickChoiceVisible: false,
      currentMajorName: "",
      currentScore: 0,
      currentRecordId: null,
      recordSearchIcon: globalRecordSearchIcon,
      searchTable: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        total: 400,
      },
      schemlTable: [],
      addList: [],
      schemlDialogVisible: false,
    };
  },
  mounted() {
    this.getRecordList();
    if (localStorage.getItem("addRecordSuccess")) {
      this.checkMore({
        id: localStorage.getItem("addRecordId"),
        majorName: localStorage.getItem("addRecordName"),
        score: localStorage.getItem("addRecordScore"),
      });
      localStorage.removeItem("addRecordSuccess");
    }
    localStorage.removeItem("addRecordId");
    localStorage.removeItem("schemeOrder");
    localStorage.removeItem("addRecordName");
    localStorage.removeItem("addRecordScore");
  },
  methods: {
    // 表格的某行数据颜色设置
    tableRowClassName({ row, rowIndex }) {
      // console.log("插入表格的数据class", this.mmval);全局变量
      if (this.mmval && this.$route.query.mmval) {
        for (var index = 0; index < this.mmval.length; index++) {
          var nn = this.mmval[index].majorId;
          if (row.majorId === nn) {
            return "warning-row";
          }
        }
      }
      return "";
    },
    handleExport(type) {
      const params = {
        userId: getUserId(),
        recordId: this.currentRecordId,
      };
      if (type === "pdf") {
        exportSchemePDF(params).then((res) => {
          const blob = new Blob([res], { type: "application/pdf" });
          try {
            if ("msSaveOrOpenBlob" in navigator) {
              window.navigator.msSaveOrOpenBlob(
                new Blob([blob]),
                `${getUserName()}_${this.currentMajorName}_${
                  this.currentScore
                }分_推荐方案.pdf`
              );
            } else {
              const url = window.URL || window.webkitURL || window.moxURL;
              const blobUrl = url.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.download = `${getUserName()}_${this.currentMajorName}_${
                this.currentScore
              }分_推荐方案.pdf`;
              a.href = blobUrl;
              a.click();
            }
          } catch (e) {
            this.$message.messageError("导出失败，请重试");
          }
        });
      } else if (type === "excel") {
        exportSchemeExcel(params).then((res) => {
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          try {
            if ("msSaveOrOpenBlob" in navigator) {
              window.navigator.msSaveOrOpenBlob(
                new Blob([blob]),
                `${getUserName()}_${this.currentMajorName}_${
                  this.currentScore
                }分_推荐方案.xlsx`
              );
            } else {
              const url = window.URL || window.webkitURL || window.moxURL;
              const blobUrl = url.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.download = `${getUserName()}_${this.currentMajorName}_${
                this.currentScore
              }分_推荐方案.xlsx`;
              a.href = blobUrl;
              a.click();
            }
          } catch (e) {
            this.$message.messageError("导出失败，请重试");
          }
        });
      }
    },
    closeSchemlDialog() {
      this.schemlDialogVisible = false;
      this.mmval = []
    },
    getRecordList() {
      const params = {
        userId: JSON.parse(localStorage.getItem("user")).userId,
        ...this.pagination,
      };
      delete params.total;
      shcemeRecord(params).then((res) => {
        if (res.code === 0) {
          this.searchTable = res.data.list;
          this.pagination.total = Number(res.data.total);
          this.pagination.pageNo = res.data.pageNum;
          this.pagination.pageSize = res.data.pageSize;
        }
      });
    },
    checkMore(row) {
      this.schemlTable = []
      this.schemlDialogVisible = true;
      this.currentRecordId = row.id;
      this.currentMajorName = row.majorName;
      this.currentScore = row.score;
      console.log("currentRecordId", this.currentRecordId);
      console.log("currentMajorName", this.currentMajorName);
      console.log("currentScore", this.currentScore);
      const params = {
        recordId: row.id,
        userId: JSON.parse(localStorage.getItem("user")).userId,
      };
      getVolunteerList(params).then((res) => {
        if (res.code === 0) {
          this.schemlTable = res.data;
          console.log("方案数据", res.data);
        }
      });
      // this.tableRowClassName(row, rowIndex);   暂时先这样吧
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getRecordList();
    },
    handleCurrentChange(val) {
      this.pagination.pageNo = val;
      this.getRecordList();
    },
    handleClickDelete(obj) {
      this.$confirm("此操作将永久删除这条方案中此条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delRecord({ id: obj.userVolunteerId }).then((res) => {
          console.info(res);
          if (res.code === 0) {
            this.$message.messageSuccess("删除成功!");
            this.checkMore({
              id: this.currentRecordId,
              majorName: this.currentMajorName,
              score: this.currentScore,
            });
          } else {
            this.$message.messageError(res.message);
          }
        });
      });
    },
    handleClickPre(obj, index) {
      const lastId = this.schemlTable[index - 1].userVolunteerId;
      console.info(index, lastId);
      console.info(obj);
      // this.$confirm('此操作将上移这条方案中此条记录, 是否继续?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // }).then(() => { // 1：顺序上调，2：顺序下调
      updSchemeOrder({
        id: obj.userVolunteerId,
        type: 1,
        lastId,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.messageSuccess("上移成功!");
          this.checkMore({
            id: this.currentRecordId,
            majorName: this.currentMajorName,
            score: this.currentScore,
          });
        } else {
          this.$message.messageError(res.message);
        }
      });
      // });
    },
    handleClickNext(obj, index) {
      const lastId = this.schemlTable[index + 1].userVolunteerId;
      console.info(index, lastId);
      console.info(obj);
      console.log("需要的id", obj.userVolunteerId);
      // this.$confirm('此操作将下移这条方案中此条记录, 是否继续?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // }).then(() => { // 1：顺序上调，2：顺序下调
      updSchemeOrder({
        id: obj.userVolunteerId,
        type: 2,
        lastId,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.messageSuccess("下移成功!");
          this.checkMore({
            id: this.currentRecordId,
            majorName: this.currentMajorName,
            score: this.currentScore,
          });
        } else {
          this.$message.messageError(res.message);
        }
      });
      // });
    },
    checkChoice() {
      console.log('用户信息',getUserId())
      let flag = new RegExp("^([1-9]|[1-9]\\d|100)$").test(this.input);
      if (!flag) {
        this.$message.messageError("请正确输入1-100的整数");
      } else {
        updateOrder({
          id: this.volId,
          number: this.input,
          schemeId: this.currentRecordId,
          userId: getUserId(),
        }).then((res) => {
          if (res.code == 0) {
            this.$message.messageSuccess("修改志愿成功！");
            this.checkMore({
              id: this.currentRecordId,
              majorName: this.currentMajorName,
              score: this.currentScore,
            });
          } else {
            this.$message.messageError(res.message);
          }
        });
      }
      this.clickChoiceVisible = false;
    },
    // 取消志愿选择
    cancleH() {
      this.clickChoiceVisible = false;
      this.$message.messageSuccess("已取消修改！");
    },
    clickChoiceDialog() {
      this.clickChoiceVisible = false;
    },
    // 志愿调整
    handleClickChoice(obj) {
      this.volId = obj.userVolunteerId;
      this.clickChoiceVisible = true;
    },
    handleClickAdd(obj) {
      var majorId = obj.majorId;
      this.addList = JSON.parse(JSON.stringify(this.schemlTable));
      let mm = this.addList.find(function(obj) {
        return obj.majorId == majorId; //取出this.studentL里的id为1的那条数据所有的信息
      });
      localStorage.setItem("schemeOrder", mm.schemeOrder);
      this.$router.push("/home");
      localStorage.setItem("goHomeAddRecord", true);
      localStorage.setItem("addRecordId", this.currentRecordId);
      localStorage.setItem("addRecordName", this.currentMajorName);
      localStorage.setItem("addRecordScore", this.currentScore);
    },
  },
};
</script>

<style lang="scss" scoped>
.inputChoice {
  width: 40%;
}
.opttllo{
   /deep/ .el-dialog__wrapper {
    position: fixed;
    top: 150px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
  }
}
.choice {
  height: 70px;
  line-height: 70px;
  font-size: 16px;
}
/deep/ .table .el-table td,
.el-table th {
  padding: 8px 0 !important;
}
/deep/ .table .el-table thead.is-group th {
  background: #f5f7fa;
  padding: 0px !important;
}
.el-table th.gutter {
  display: table-cell !important;
}
.el-dialog__headerbtn {
  top: 12px;
}
.programme /deep/.el-dialog__body {
  padding: 8px 8px;
}
[data-v-7e062d75] .el-dialog__header  {
  height: 40px;
}
.check {
  color: #409eff;

  &:hover {
    cursor: pointer;
  }
}

/deep/ .el-dialog__header {
  height: 50px;
}

/deep/ .el-table__body-wrapper {
  height: 300px;
  overflow-y: auto;
}
</style>

<style>
/* 插入表格的一行的数据 */
.warning-row {
  color: red !important;
}

/* 表格偏移对不齐 */
.el-table th.gutter {
  display: table-cell !important;
}
</style>
